import React from 'react';
import clsx from 'clsx';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import { LocalVideoTrack, Participant, RemoteVideoTrack } from 'twilio-video';

import BandwidthWarning from '../BandwidthWarning/BandwidthWarning';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../../hooks/usePublications/usePublications';
import useTrack from '../../hooks/useTrack/useTrack';
import VideocamOff from '@material-ui/icons/VideocamOff';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      gridArea: 'participantList',
      left: '0',
      right: '0',
      top: '0',
      bottom: '0',
      height: '100%',
      padding: '20px',
      width: '100%',
      '& video': {
        borderRadius: '5px',
        objectFit: 'cover',
        height: '100%',
        objectPosition: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0px',

        '& video': {
          borderRadius: '0px',
        },
      },
    },
    isVideoSwitchedOff: {
      background: 'rgba(28,32,36,1)',
      position: 'absolute',
      height: 'calc(100% - 40px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'calc(100% - 40px)',
      borderRadius: '5px',
      top: '20px',
      left: '20px',

      '& video': {
        background: 'rgba(28,32,36,1)',
      },
    },
    identity: {
      background: 'rgba(0, 0, 0, 0.7)',
      padding: '0.1em 0.3em',
      margin: '1em',
      fontSize: '1.2em',
      display: 'inline-flex',
      '& svg': {
        marginLeft: '0.3em',
      },
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 1,
    },
  })
);

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
}

export default function MainParticipantInfo({ participant, children }: MainParticipantInfoProps) {
  const classes = useStyles();

  const publications = usePublications(participant);
  const videoPublication = publications.find(p => p.trackName.includes('camera'));
  const screenSharePublication = publications.find(p => p.trackName.includes('screen'));
  const isVideoEnabled = Boolean(videoPublication);

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  return (
    <div
      data-cy-main-participant
      className={clsx(classes.container, { [classes.isVideoSwitchedOff]: isVideoSwitchedOff })}
    >
      <div className={clsx(classes.infoContainer, { [classes.isVideoSwitchedOff]: !isVideoEnabled })}>
        {/*<h4 className={classes.identity}>*/}
        {/*  {participant.identity}*/}
          {!isVideoEnabled && <VideocamOff style={{color:"white", fontSize: "50px"}} />}
        {/*</h4>*/}
      </div>
      {isVideoSwitchedOff && <BandwidthWarning />}
      {children}
    </div>
  );
}
