import React from 'react';
import MenuBar from "../MenuBar/MenuBar";
import LocalVideoPreview from "../LocalVideoPreview/LocalVideoPreview";
import Room from "../Room/Room";
import Controls from "../Controls/Controls";
import ReconnectingNotification from "../ReconnectingNotification/ReconnectingNotification";
import { styled } from '@material-ui/core/styles';


import useHeight from '../../hooks/useHeight/useHeight';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import {CircularProgress} from "@material-ui/core";

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
});

const Main = styled('main')({
  overflow: 'hidden',
  position: 'relative',
});

export default function Home() {
  const roomState = useRoomState();

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  return (
    <Container style={{ height }}>
      <MenuBar />
      <Main>
        {roomState === 'disconnected' ? <LocalVideoPreview /> : <Room />}
        <Controls />
      </Main>
      <ReconnectingNotification />
    </Container>
  );
}
