import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { createMuiTheme, createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import ToggleFullscreenButton from './ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from './Menu/Menu';

import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography } from '@material-ui/core';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import LocalAudioLevelIndicator from './DeviceSelector/LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import Drawer from '@material-ui/core/Drawer';
import Modal from '@material-ui/core/Modal';
import logo from '../../assets/images/aryaLogo.png';
import { roomService } from '../../services/room.service';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      backgroundColor: 'white!important' as any,
    },
    container: {
      backgroundColor: 'white!important' as any,
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
      backgroundColor: 'rgb(33,147,134)!important' as any,
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginLeft: '2.2em',
      },
    },
    textField: {
      margin: 0,
      marginBottom: 10,
      width: 200,
      color: 'white',
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    aryaLogo: {
      padding: 0,
    },
    aryaLogoImage: {
      height: '50px',
      width: '156px',
      boxSizing: 'border-box',
      objectFit: 'contain',
      objectPosition: 'left',
      marginTop: '5px',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
      color: 'white',
    },
    modalContainer: {
      backgroundColor: 'white',
      height: 'auto',
      width: 'auto',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '30px',
      fontSize: 15,
      color: 'black',
    },
    joinButton: {
      marginTop: 20,
      width: '200px',
      background: 'rgb(33,147,134)',
      color: 'white',

      '&:hover': {},
    },
    modalText: {
      fontSize: '17px',
      color: 'rgb(35,35,35)',
      width: '100%',
      textAlign: 'left',
      fontWeight: 600,
    },
    modalOpen: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [gotRoomFromURL, setGotRoomFromURL] = React.useState(false);
  const [chatDrawerOpen, setChatDrawerOpen] = React.useState(false);
  const { URLRoomName } = useParams();
  const { user, getToken, isFetching } = useAppState();
  const { isConnecting, connect, isAcquiringLocalTracks } = useVideoContext();
  const roomState = useRoomState();
  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [participantId, setParticipantId] = useState<string | null>(null);

  useEffect(() => {
    const parsedUrl: URL = new URL(window.location.href);
    let splitPathName: string[] = parsedUrl.pathname.split('/');

    if (URLRoomName) {
      setRoomName(URLRoomName);
    }

    if (splitPathName.length > 2) {
      let room = splitPathName[splitPathName.length - 2];
      let roomID = splitPathName[splitPathName.length - 1];

      if (room === 'room' && roomID != null && roomID.length > 0) {
        setRoomName(roomID);
        setGotRoomFromURL(true);
      }
    }

    if (parsedUrl.searchParams.get('participant_id')) {
      setParticipantId(parsedUrl.searchParams.get('participant_id'));
    }
  }, [URLRoomName]);

  const onCloseChatDrawer = () => {
    setChatDrawerOpen(false);
  };

  const onToggleChatDrawer = () => {
    console.log(window.location.href, roomName);
    setChatDrawerOpen(!chatDrawerOpen);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    roomService.connect(name, roomName, participantId).then(
      token => {
        connect(token);
      },
      error => {
        setErrorMessage(error.toString());
      }
    );

    // TODO: Renable if running on Node Server.
    // // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    // if(!window.location.origin.includes('twil.io')){
    //   window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
    // }
    // getToken(name, roomName).then(token => connect(token));
  };

  return (
    <>
      <AppBar className={classes.container} position="static">
        <Toolbar className={classes.toolbar}>
          <div className={classes.aryaLogo}>
            <img src={logo} className={classes.aryaLogoImage} />
          </div>

          <div className={classes.rightButtonContainer}>
            <FlipCameraButton />
            <LocalAudioLevelIndicator />
            <ToggleFullscreenButton />
            <Menu />
          </div>

          {/*<button onClick={onToggleChatDrawer}>*/}
          {/*  <span>Chat</span>*/}
          {/*</button>*/}
        </Toolbar>

        <Modal open={roomState === 'disconnected'} className={classes.modalOpen}>
          <form className={classes.modalContainer} onSubmit={handleSubmit}>
            {errorMessage.length > 0 ? (
              <div className={classes.modalText}>{errorMessage}</div>
            ) : (
              <div className={classes.modalText}>Enter your name before entering the room.</div>
            )}
            {window.location.search.includes('customIdentity=true') || !user?.displayName ? (
              <TextField
                id="menu-name"
                label="Name"
                className={classes.textField}
                value={name}
                onChange={handleNameChange}
              />
            ) : (
              <Typography className={classes.displayName} variant="body1">
                {user.displayName}
              </Typography>
            )}

            {gotRoomFromURL === false ? (
              <TextField
                id="menu-room"
                label="Room"
                className={classes.textField}
                value={roomName}
                onChange={handleRoomNameChange}
              />
            ) : (
              <div></div>
            )}

            <Button
              className={classes.joinButton}
              type="submit"
              variant="contained"
              disabled={isAcquiringLocalTracks || isConnecting || !name || !roomName || isFetching}
            >
              Join Room
            </Button>
            {(isConnecting || isFetching) && (
              <CircularProgress
                className={classes.loadingSpinner}
                style={{ color: 'rgb(33,147,134)', marginTop: '10' }}
              />
            )}
          </form>
        </Modal>

        {/*<CircularProgress/>*/}
      </AppBar>

      {/*<Drawer anchor="right" open={chatDrawerOpen} onClose={onCloseChatDrawer}>*/}
      {/*  <div onClick={onCloseChatDrawer}>*/}
      {/*    Chat Window*/}
      {/*  </div>*/}
      {/*</Drawer>*/}
    </>
  );
}
