import axios, { AxiosResponse } from 'axios';
import { ApiUrl } from '../constants';

const ErrorCodeHasNotReachedStartAtThreshold: number = 1;
const ErrorCodeHasReachedEndAtThreshold: number = 2;

export const roomService = {
  connect: (name: string, room: string, participantId?: string | null, passcode?: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ApiUrl}twilio_video/rooms/connect`, {
          room_id: room,
          participant_name: name,
          participant_id: participantId,
          passcode: passcode,
        })
        .then(
          (response: AxiosResponse) => {
            const token = response?.data?.token;

            if (token) {
              resolve(token);
            } else {
              reject();
            }
          },
          (error: any) => {
            if (error.response && error.response.data && error.response.data.error_code) {
              let errorCode = error.response.data.error_code;

              switch (errorCode) {
                case ErrorCodeHasNotReachedStartAtThreshold:
                  reject('It’s not time for the meeting yet.');
                  break;
                case ErrorCodeHasReachedEndAtThreshold:
                  reject('The appointment is over.');
                  break;
                default:
                  reject('Unknown Error');
              }
            } else {
              reject(error);
            }
          }
        );
    });
  },
};
